import * as React from "react";
import { TAvailableSurveyLanguages } from "../../types/Survey";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SurveyTranslation from "./SurveyTranslation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(8),
    },
  })
);

interface ILanguageSelectorProps {
  languages: TAvailableSurveyLanguages;
  language: string;
  selectLanguage: string | null;
  changeSurveyLanguage: (lang: string) => void;
}

const LanguageSelector: React.FC<ILanguageSelectorProps> = ({
  languages,
  language,
  selectLanguage,
  changeSurveyLanguage,
}): JSX.Element => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="language-label">
        {selectLanguage !== null ? (
          <>{selectLanguage}</>
        ) : (
          <SurveyTranslation translate={"survey.language.select.label"} />
        )}
      </InputLabel>
      {language && (
        <Select
          labelId="language-label"
          id="language-select"
          value={language}
          onChange={(e) => changeSurveyLanguage(e.target.value as string)}
          label={
            selectLanguage !== null ? (
              <>{selectLanguage}</>
            ) : (
              <SurveyTranslation translate={"survey.language.select.label"} />
            )
          }
        >
          {languages.map((lang) => {
            return (
              <MenuItem
                value={lang.language.languageCode}
                key={lang.language.languageCode}
              >
                {lang.language.name}
              </MenuItem>
            );
          })}
        </Select>
      )}
    </FormControl>
  );
};

export default LanguageSelector;
