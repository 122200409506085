import * as React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { TApiActionPlanEntity } from '../../types/Actionplan';
import ActionPlanAccordion from './ActionPlanAccordion';
import ActionPlanDetails from './ActionPlanDetails';
import AlertBlock from '../AlertBlock';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
  })
);

interface IActionPlanItemProps {
  actionPlan: TApiActionPlanEntity;
}

const ActionPlanItem: React.FC<IActionPlanItemProps> = ({
  actionPlan,
}): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {actionPlan && (
        <>
          {actionPlan.news && (
            <AlertBlock type={'info'} hideCloseButton={true}>
              <div dangerouslySetInnerHTML={{ __html: actionPlan.news }} />
            </AlertBlock>
          )}
          <ActionPlanAccordion
            problem={actionPlan.problem}
            consequence={actionPlan.consequence}
            solution={actionPlan.solution}
          />
          <ActionPlanDetails actionPlan={actionPlan} />
        </>
      )}
    </div>
  );
};

export default ActionPlanItem;
