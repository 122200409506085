import * as React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { simpleDateTimeFormat } from '../../Helpers';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { TApiMeetingEntity } from '../../types/Meeting';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import { Alert } from '@material-ui/lab';
import { fetchWithAuthentication } from '../../actions/BaseAction';

interface IChemicalProductProps {
  meeting: TApiMeetingEntity;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(3),
    },
    table: {
      width: '100%',
    },
    title: {
      flex: '1 1 100%',
      padding: theme.spacing(1, 2),
    },
    title2: {
      fontWeight: 'normal',
      padding: theme.spacing(3, 0),
    },
    minutes: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(0, 1),
    },
  })
);

const Meeting: React.FC<IChemicalProductProps> = ({ meeting }): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(true);
  const [meetingFile, setMeetingFile] = useState('*');

  useEffect(() => {
    if (meeting.file) {
      fetchWithAuthentication(meeting.file)
        .then(blob => {
          if (blob !== null) {
            setMeetingFile(URL.createObjectURL(blob));
          }
        })
        .catch(error => {throw error});
    }
  }, [meeting.file]);

  const handleChange = () => (event: ChangeEvent<{}>, expanded: boolean) => {
    setExpanded(expanded);
  };

  return (
    meeting && (
      <>
        <ExpansionPanel expanded={expanded} onChange={handleChange()}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            style={{
              paddingLeft: 0,
            }}
          >
            <Typography className={classes.title} variant='h6'>
              {t('meetings.meeting_details')}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <TableContainer>
              <Table className={classes.table} size='small'>
                <TableBody>
                  <TableRow
                    style={{
                      verticalAlign: 'top',
                    }}
                  >
                    <TableCell component='th' scope='row'>
                      <Box fontWeight={'bold'}>{t('meetings.status')}</Box>
                    </TableCell>
                    <TableCell align='right'>
                      <Chip
                        style={{
                          backgroundColor: meeting.meeting.enabled
                            ? '#8dc63f'
                            : '#cc3f44',
                          color: '#fff',
                          textTransform: 'uppercase',
                        }}
                        size='small'
                        label={
                          meeting.meeting.enabled
                            ? t('meetings.status.open')
                            : t('meetings.status.closed')
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={{
                      verticalAlign: 'top',
                    }}
                  >
                    <TableCell component='th' scope='row'>
                      <Box fontWeight={'bold'}>{t('meetings.organizer')}</Box>
                    </TableCell>
                    <TableCell align='right'>
                      {meeting.meeting.organizer}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={{
                      verticalAlign: 'top',
                    }}
                  >
                    <TableCell component='th' scope='row'>
                      <Box fontWeight={'bold'}>
                        {t('meetings.organisations')}
                      </Box>
                    </TableCell>
                    <TableCell align='right'>
                      {meeting.meeting.organisations.map((obj) => {
                        return (
                          <>
                            {obj}
                            <br />
                          </>
                        );
                      })}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={{
                      verticalAlign: 'top',
                    }}
                  >
                    <TableCell component='th' scope='row'>
                      <Box fontWeight={'bold'}>
                        {t('meetings.date_and_time')}
                      </Box>
                    </TableCell>
                    <TableCell align='right'>
                      {simpleDateTimeFormat(meeting.meeting.startTime)}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={{
                      verticalAlign: 'top',
                      border: 0,
                    }}
                  >
                    <TableCell
                      component='th'
                      scope='row'
                      style={{
                        border: 0,
                      }}
                    >
                      <Box fontWeight={'bold'}>{t('meetings.attendees')}</Box>
                    </TableCell>
                    <TableCell
                      align='right'
                      style={{
                        border: 0,
                      }}
                    >
                      {meeting.meeting.attendees.map((obj) => {
                        return (
                          <>
                            {obj.name}
                            {obj.title ? ', ' + obj.title : ''}
                            <br />
                          </>
                        );
                      })}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {meeting.agenda && (
          <>
            <Typography className={classes.title2} variant='h6'>
              {t('meetings.agenda_and_minutes')}
            </Typography>

            <TableContainer>
              <Table className={classes.table} size='small'>
                <TableBody>
                  {meeting.agenda.map((obj, i) => {
                    const minutes = obj.minutes.trim();

                    return (
                      <TableRow
                        key={i}
                        style={{
                          verticalAlign: 'top',
                        }}
                      >
                        <TableCell>{i + 1}</TableCell>
                        <TableCell>
                          <b>{obj.name}</b>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: obj.description,
                            }}
                          />
                          {minutes && (
                            <Alert
                              severity={'info'}
                              icon={false}
                              className={classes.minutes}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: minutes,
                                }}
                              />
                            </Alert>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        {meeting.file && (
          <>
            <Grid container className={classes.root} justifyContent="space-around">
              <Grid item xs={6}>
                <Button
                  type='button'
                  fullWidth
                  variant='contained'
                  color='primary'
                  target={'_blank'}
                  href={meetingFile}
                  style={{
                    textTransform: 'uppercase',
                  }}
                >
                  {t('meetings.agenda_and_minutes')}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </>
    )
  );
};

export default Meeting;
