import * as React from 'react';
import { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import {
  ActionPlanRiskLevel,
  ActionPlanStatus,
  TApiActionPlanEntity,
} from '../../types/Actionplan';
import { simpleDateFormat } from '../../Helpers';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import {
  getActionPlanStyling,
  getActionRiskStyling,
} from '../../containers/pages/actionplans/ActionPlanListPage';
import { useDispatch } from 'react-redux';
import { setCurrentPageTitle } from '../../actions/ContentActions';

interface IActionPlanDetailsProps {
  actionPlan: TApiActionPlanEntity;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(3),
    },
    table: {
      width: '100%',
    },
    title: {
      flex: '1 1 100%',
      padding: theme.spacing(1, 2),
    },
  })
);

const ActionPlanDetails: React.FC<IActionPlanDetailsProps> = ({
  actionPlan,
}): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentPageTitle(actionPlan.name));
  }, [dispatch, actionPlan.name]);

  return (
    <Paper className={classes.root}>
      <TableContainer>
        <Typography className={classes.title} variant='h6' id='tableTitle'>
          {t('actionplan.details')}
        </Typography>
        <Table
          className={classes.table}
          size='small'
          aria-label='a dense table'
        >
          <TableBody>
            <TableRow>
              <TableCell component='th' scope='row'>
                <Box fontWeight={'bold'}>{t('actionplan.status')}</Box>
              </TableCell>
              <TableCell align='right'>
                <Chip
                  style={{
                    backgroundColor: getActionPlanStyling(
                      actionPlan.status as ActionPlanStatus
                    ).color,
                    color: '#fff',
                    textTransform: 'uppercase',
                  }}
                  size='small'
                  label={t(actionPlan.status)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>
                <Box fontWeight={'bold'}>{t('actionplan.risk')}</Box>
              </TableCell>
              <TableCell align='right'>
                <Chip
                  style={{
                    backgroundColor: getActionRiskStyling(
                      actionPlan.risk as ActionPlanRiskLevel
                    ),
                    color: '#fff',
                    textTransform: 'uppercase',
                  }}
                  size='small'
                  label={t(actionPlan.risk)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>
                <Box fontWeight={'bold'}>{t('actionplan.responsible')}</Box>
              </TableCell>
              <TableCell align='right'>
                {actionPlan.responsiblePerson}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>
                <Box fontWeight={'bold'}>{t('actionplan.amr')}</Box>
              </TableCell>
              <TableCell align='right'>
                {actionPlan.safetyRepresentative}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>
                <Box fontWeight={'bold'}>{t('actionplan.endDate')}</Box>
              </TableCell>
              <TableCell align='right'>
                {simpleDateFormat(actionPlan.endDate)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>
                <Box fontWeight={'bold'}>{t('actionplan.latest.activity')}</Box>
              </TableCell>
              <TableCell align='right'>
                {simpleDateFormat(actionPlan.latestActivity)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>
                <Box fontWeight={'bold'}>{t('actionplan.startDate')}</Box>
              </TableCell>
              <TableCell align='right'>
                {simpleDateFormat(actionPlan.startDate)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ActionPlanDetails;
