/*
    CMS
 */
import { AxiosError, AxiosResponse } from 'axios';

export enum ContentPage {
  FRONTPAGE = 1,
  TEXT_PAGE = 2,
  SURVEYS_LIST = 3,
  SURVEY_START = 4,
  SURVEY_PROGRESS = 5,
  ACTIONPLANS = 7,
  ACTIONPLAN = 9,
  ROOT_PAGE = 10,
  CASES_PAGE = 11,
  CHEMICALS_PAGE = 12,
  INSPECTIONS_LIST = 13,
  MEETINGS = 14,
  WHISTLEBLOWER = 15,
}

export enum SurveyInspectionType {
  SURVEY = 1,
  INSPECTION = 2,
}

export interface IPageContentArticle {
  headline: string;
  bodyText: string;
}

export interface IPageContentImage {
  name: string;
  url: string;
}

export interface IPageContent {
  id: number;
  type: ContentPage;
  name: string;
  url: string;
  articles: Array<IPageContentArticle>;
  images: Array<IPageContentImage>;
}

type CustomPageTitle = {
  title: string;
  url: string;
};

export interface TContentState {
  readonly data?: IPageContent;
  readonly customPages?: Array<IPageContent>;
  readonly indexedData?: any;
  readonly loading?: boolean;
  readonly currentPageTitle?: CustomPageTitle;
  readonly error?: string;
}

export const initialContentState: TContentState = {};

export enum GetPageContent {
  REQUEST = 'GET_PAGE_CONTENT_REQUEST',
  SUCCESS = 'GET_PAGE_CONTENT_SUCCESS',
  FAILED = 'GET_PAGE_CONTENT_FAILED',
}

export type TGetPageContentAction =
  | {
      type: GetPageContent.REQUEST;
    }
  | {
      type: GetPageContent.SUCCESS;
      payload: AxiosResponse<{ menuItem: IPageContent }>;
    }
  | {
      type: GetPageContent.FAILED;
      payload: AxiosError;
    };

export enum GetCustomPages {
  REQUEST = 'GET_CUSTOM_PAGES_REQUEST',
  SUCCESS = 'GET_CUSTOM_PAGES_SUCCESS',
  FAILED = 'GET_CUSTOM_PAGES_FAILED',
}

export type TGetCustomPagesAction =
  | {
      type: GetCustomPages.REQUEST;
    }
  | {
      type: GetCustomPages.SUCCESS;
      payload: AxiosResponse<{ items: Array<IPageContent> }>;
    }
  | {
      type: GetCustomPages.FAILED;
      payload: AxiosError;
    };

export enum SetCurrentPageTitle {
  REQUEST = 'SET_CURRENT_PAGE_TITLE_REQUEST',
  SUCCESS = 'SET_CURRENT_PAGE_TITLE',
  FAILED = 'SET_CURRENT_PAGE_TITLE_FAILED',
}

export type TSetCurrentPageTitleAction =
  | {
      type: SetCurrentPageTitle.REQUEST;
    }
  | {
      type: SetCurrentPageTitle.SUCCESS;
      payload: CustomPageTitle;
    }
  | {
      type: SetCurrentPageTitle.FAILED;
    };

export type TContentActions =
  | TGetPageContentAction
  | TGetCustomPagesAction
  | TSetCurrentPageTitleAction;
