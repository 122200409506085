import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Separator from '../../../components/Separator';
import SocialButtons from '../../../components/auth/SocialButtons';
import { TAuthState, UserLogin } from '../../../types/Auth';
import { useApp } from '../../../hooks/useApp';
import LoginForm from '../../../components/forms/auth/LoginForm';
import { TDispatch } from '../../../types/Thunk';
import { RouteComponentProps } from 'react-router';
import { routes } from '../../../Routes';
import { withRouter } from 'react-router';
import { loginUser, unifiedUserLogin } from '../../../actions/AuthActions';
import Container from '@material-ui/core/Container';
import { getCustomPages } from '../../../actions/ContentActions';
import { IAppState } from '../../../store/Store';
import AlertBlock from '../../../components/AlertBlock';

interface ILoginPageProps extends RouteComponentProps<any> {
  fallbackUrl?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const LoginPage: React.FC<ILoginPageProps> = (props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch<TDispatch>();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [recaptcha, setRecaptcha] = useState('');
  const [, setError] = useState('');
  const { app } = useApp();
  const query = new URLSearchParams(props.location.search);
  const unifiedKey = query.get('authenticate');
  const fallbackUrl = query.get('payload[fallbackUrl]');
  const auth: TAuthState = useSelector<IAppState, TAuthState>(
    (state: IAppState) => state.authState
  );

  const dispatchLoginAction = async () => {
    await dispatch(loginUser(username, password, recaptcha));
  };

  const dispatchUnifiedLoginAction = async (unifiedKey: string) => {
    await dispatch(unifiedUserLogin(unifiedKey));
  };

  const submitLoginForm = async (e: FormEvent) => {
    e.preventDefault();
    await dispatchLoginAction()
      .then(() => {
        document.body.classList.remove('centered-content');
        (async () => {
          await dispatch(getCustomPages());
        })();
        if (props.fallbackUrl) {
          return props.history.push(props.fallbackUrl);
        }
        return props.history.push(routes.home);
      })
      .catch((e) => {
        setError(e.response.message);
      });
  };

  const handleUnifiedLogin = async (unifiedKey: string) => {
    await dispatchUnifiedLoginAction(unifiedKey)
      .then(() => {
        if (fallbackUrl) {
          return props.history.push(fallbackUrl);
        }
        if (props.fallbackUrl) {
          return props.history.push(props.fallbackUrl);
        }
        return window.location.replace(routes.home);
      })
      .catch((e) => {
        setError(e.response.message);
      });
  };

  useEffect(() => {
    if (
      unifiedKey &&
      unifiedKey !== '-1' &&
      !auth.loading &&
      !auth.data?.user
    ) {
      handleUnifiedLogin(unifiedKey as string);
    }
    //eslint-disable-next-line
  }, []);

  if (app.isLoading()) {
    return <div />;
  }

  return (
    <Container component='main' maxWidth='xs'>
      <Typography align={'center'}>{t('login.page.intro')}</Typography>
      <div className={classes.paper}>
        {unifiedKey === '-1' && (
          <AlertBlock type={'error'}>
            {t('login.google.microsoft.login.error')}
          </AlertBlock>
        )}
        <SocialButtons
          googleAllowed={auth.settings?.loginConfig.googleAllowed || false}
          microsoftAllowed={
            auth.settings?.loginConfig.microsoftAllowed || false
          }
        />
        {(auth.settings?.loginConfig.googleAllowed ||
          auth.settings?.loginConfig.microsoftAllowed) &&
          auth.settings.loginConfig.localAllowed && <Separator />}

        {auth.settings?.loginConfig.localAllowed && (
          <>
            <LoginForm
              errors={app.getError(UserLogin)}
              username={username}
              setUsername={setUsername}
              password={password}
              setPassword={setPassword}
              recaptcha={recaptcha}
              setRecaptcha={setRecaptcha}
              submitForm={submitLoginForm}
            />
          </>
        )}
      </div>
    </Container>
  );
};

export default withRouter(LoginPage);
