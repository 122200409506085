import * as React from "react";
import "./assets/styles/css/App.css";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import { useApp } from "./hooks/useApp";
import Loader from "./components/Loader";
import Logo from "./components/Logo";
import CssBaseline from "@material-ui/core/CssBaseline";
import { TAuthState } from "./types/Auth";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "./store/Store";
import { SurveyType, TSurveysState } from "./types/Survey";
import { useEffect } from "react";
import { TDispatch } from "./types/Thunk";
import { getAppSettings } from "./actions/AuthActions";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import ErrorPage from "./components/ErrorPage";
import { TErrorState } from "./types/Error";
import AppRoutes from "./AppRoutes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import UploadsComponent from './UploadsComponent';

const queryClient = new QueryClient();

const App: React.FC = () => {
  const { app } = useApp();
  const dispatch = useDispatch<TDispatch>();
  const { i18n } = useTranslation();
  const auth: TAuthState = useSelector<IAppState, TAuthState>(
    (state: IAppState) => state.authState
  );
  const surveysState: TSurveysState = useSelector<IAppState, TSurveysState>(
    (state: IAppState) => state.surveysState
  );
  const errorState: TErrorState = useSelector<IAppState, TErrorState>(
    (state: IAppState) => state.errorState
  );

  useEffect(() => {
    (async () => {
      await dispatch(getAppSettings()).then((res: AxiosResponse<any>) => {
        i18n.changeLanguage(res.data.languageCode);
      });
    })();
    //eslint-disable-next-line
  }, []);

  if (auth.loading) {
    return <Loader visible={true} />;
  }

  if (errorState.code && errorState.message && errorState.code !== 401) {
    return <ErrorPage message={errorState.message} />;
  }

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename="/app">
          <UploadsComponent>
            <Logo
              visible={
                !localStorage.getItem("token") ||
                (auth?.data?.user === null &&
                  surveysState.currentSurvey?.survey.anonymity ===
                    SurveyType.REQUIRES_LOGIN)
              }
            />
            <CssBaseline />
            <Loader visible={app.isLoading()} />
            <AppRoutes />
          </UploadsComponent>
        </BrowserRouter>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
