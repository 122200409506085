import * as React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { Link } from 'react-router-dom';
import { routes } from '../../Routes';
import { ContentPage, IPageContent, TContentState } from '../../types/Content';
import { IAppState } from '../../store/Store';
import { useSelector } from 'react-redux';
import { reverse } from 'named-urls';
import HomeIcon from '@material-ui/icons/Home';
import ForumIcon from '@material-ui/icons/Forum';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FolderIcon from '@material-ui/icons/Folder';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import BallotIcon from '@material-ui/icons/Ballot';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles, Theme } from '@material-ui/core/styles';
import GroupIcon from '@material-ui/icons/Group';
import SecurityIcon from '@material-ui/icons/Security';

interface INavbarItemListProps {
  close: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    minWidth: '35px',
    marginTop: '3px',
  },
  item: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: '4px',
    paddingBottom: '4px',
    wordBreak: 'break-word',
  },
}));

const NavbarItemList: React.FC<INavbarItemListProps> = (props): JSX.Element => {
  const contentState: TContentState = useSelector<IAppState, TContentState>(
    (state) => state.contentState
  );
  const classes = useStyles();

  const pageContent = (page: IPageContent) => {
    switch (page.type) {
      case ContentPage.SURVEYS_LIST:
        return routes.surveyList;

      case ContentPage.INSPECTIONS_LIST:
        return routes.inspectionList;

      case ContentPage.ACTIONPLANS:
        return routes.actionPlanList;

      case ContentPage.TEXT_PAGE:
        return reverse(routes.cmsPage, {
          id: page.id,
        });

      case ContentPage.CASES_PAGE:
        return routes.reportCase;

      case ContentPage.CHEMICALS_PAGE:
        return routes.chemicalsList;

      case ContentPage.MEETINGS:
        return routes.meetingsList;

      case ContentPage.WHISTLEBLOWER:
        return routes.whistleblower;
    }

    return routes.home;
  };

  const getMenuItemIcon = (page: IPageContent) => {
    switch (page.type) {
      case ContentPage.FRONTPAGE:
        return <HomeIcon />;

      case ContentPage.SURVEYS_LIST:
        return <ForumIcon />;

      case ContentPage.INSPECTIONS_LIST:
        return <BallotIcon />;

      case ContentPage.ACTIONPLANS:
        return <DateRangeIcon />;

      case ContentPage.TEXT_PAGE:
        return <FolderIcon />;

      case ContentPage.CASES_PAGE:
        return <AssignmentLateIcon />;

      case ContentPage.CHEMICALS_PAGE:
        return (
          <i
            className='fas fa-flask'
            style={{ fontSize: '21px', padding: '2px 3px' }}
          />
        );

      case ContentPage.MEETINGS:
        return <GroupIcon />;

      case ContentPage.WHISTLEBLOWER:
        return <SecurityIcon />;
    }

    return <></>;
  };

  return (
    <>
      <List>
        {contentState.customPages?.map((page) => {
          // if (page.type === ContentPage.WHISTLEBLOWER) return null;

          return (
            <ListItem
              button
              onClick={props.close}
              component={Link}
              to={page.id ? pageContent(page) : routes.home}
              key={page.id}
              className={classes.item}
            >
              <ListItemIcon
                className={classes.icon}
                children={getMenuItemIcon(page)}
              />
              <ListItemText primary={page.name} />
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default NavbarItemList;
