import { AxiosError, AxiosResponse } from 'axios';

export interface TApiActionPlanEntity {
  id: number;
  name: string;
  status: string;
  statusLabel: string;
  startDate: Date;
  endDate: Date;
  problem?: string;
  consequence?: string;
  solution?: string;
  news?: string;
  risk: string;
  responsiblePerson?: string;
  safetyRepresentative?: string;
  latestActivity?: Date;
}

export interface TApiActionPlanCategory {
  id: number;
  name: string;
  orderNr: number;
  description: string;
  parentId: number;
}

export enum ActionPlanStatus {
  DRAFT = 'draft',
  OPEN = 'open',
  COMPLETED = 'completed',
  CLOSING = 'closing',
  OVERDUE = 'overdue',
  SCHEDULED = 'scheduled',
  REVISED = 'revised',
}

export enum ActionPlanRiskLevel {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export interface TActionPlanState {
  readonly data?: Array<TApiActionPlanEntity>;
  readonly activeActionPlan?: TApiActionPlanEntity;
  readonly categories?: Array<TApiActionPlanCategory>;
}

export const initialActionPlanState: TActionPlanState = {};

/*
    ActionPlan categories
 */
export enum ActionPlanCategories {
  REQUEST = 'ACTION_PLAN_CATEGORIES_REQUEST',
  SUCCESS = 'ACTION_PLAN_CATEGORIES_SUCCESS',
  FAILED = 'ACTION_PLAN_CATEGORIES_FAILED',
}

export type TGetActionPlanCategories =
  | {
      type: ActionPlanCategories.REQUEST;
    }
  | {
      type: ActionPlanCategories.SUCCESS;
      payload: AxiosResponse<{ categories: Array<TApiActionPlanCategory> }>;
    }
  | {
      type: ActionPlanCategories.FAILED;
      payload: AxiosError;
    };

/*
    ActionPlan list
 */
export enum ActionPlanList {
  REQUEST = 'ACTION_PLAN_LIST_REQUEST',
  SUCCESS = 'ACTION_PLAN_LIST_SUCCESS',
  FAILED = 'ACTION_PLAN_LIST_FAILED',
}

export type TGetActionPlanList =
  | {
      type: ActionPlanList.REQUEST;
    }
  | {
      type: ActionPlanList.SUCCESS;
      payload: AxiosResponse<{ plans: Array<TApiActionPlanEntity> }>;
    }
  | {
      type: ActionPlanList.FAILED;
      payload: AxiosError;
    };

/*
    ActionPlan
 */
export enum ActionPlan {
  REQUEST = 'ACTION_PLAN_REQUEST',
  SUCCESS = 'ACTION_PLAN_SUCCESS',
  FAILED = 'ACTION_PLAN_FAILED',
}

export type TGetActionPlan =
  | {
      type: ActionPlan.REQUEST;
    }
  | {
      type: ActionPlan.SUCCESS;
      payload: AxiosResponse<{ plan: TApiActionPlanEntity }>;
    }
  | {
      type: ActionPlan.FAILED;
      payload: AxiosError;
    };

export type TActionPlanActions =
  | TGetActionPlanCategories
  | TGetActionPlanList
  | TGetActionPlan;
